// ** Icon imports
import { BarChartRounded, Business, People } from '@mui/icons-material'
import Finance from 'mdi-material-ui/Finance'
import NetworkPos from 'mdi-material-ui/NetworkPos'

// ** Type import
import { VerticalNavItemsType } from 'src/@core/layouts/types'

const navigation = (): VerticalNavItemsType => {

  return [
    // {
    //   title: 'Dashboard',
    //   icon: HomeOutline,
    //   path: '/'
    // },

    // {
    //   title: 'Account Settings',
    //   icon: AccountCogOutline,
    //   path: '/account-settings'
    // },
    {
      sectionTitle: 'Give Plus',
      appLevel: 2
    },
    {
      title: 'Terminals',
      icon: NetworkPos,
      path: '/givePlus/terminal',
      appLevel: 2
    },
    {
      title: 'Transactions',
      icon: Finance,
      path: '/givePlus/transactions',
      appLevel: 2
    },
    {
      title: 'Reports',
      icon: BarChartRounded,
      path: '/givePlus/reports',
      appLevel: 2
    },
    {
      sectionTitle: 'Give',
      appLevel: 1
    },
    {
      title: 'Terminals',
      icon: NetworkPos,
      path: '/give/terminal',
      appLevel: 1
    },
    {
      title: 'Transactions',
      icon: Finance,
      path: '/give/transactions',
      appLevel: 1
    },
    {
      title: 'Reports',
      icon: BarChartRounded,
      path: '/give/reports',
      appLevel: 1
    },
    {
      sectionTitle: 'Client Management',
      appLevel: 3
    },
    {
      title: 'Clients',
      icon: Business,
      path: '/clients',
      appLevel: 3
    },
    {
      title: 'Users',
      icon: People,
      path: '/users',
      appLevel: 3
    },
    // {
    //   sectionTitle: 'Statistics',
    //   alwaysDisplay: true
    // },
    // {
    //   title: 'Invoices',
    //   icon: Receipt,
    //   path: '/invoices',
    //   alwaysDisplay: true
    // },

    // {
    //   sectionTitle: 'User Interface'
    // },
    // {
    //   title: 'Typography',
    //   icon: FormatLetterCase,
    //   path: '/typography'
    // },
    // {
    //   title: 'Icons',
    //   path: '/icons',
    //   icon: GoogleCirclesExtended
    // },
    // {
    //   title: 'Cards',
    //   icon: CreditCardOutline,
    //   path: '/cards'
    // },
    // {
    //   title: 'Tables',
    //   icon: Table,
    //   path: '/tables'
    // },
    // {
    //   icon: CubeOutline,
    //   title: 'Form Layouts',
    //   path: '/form-layouts'
    // }
  ]
}

export default navigation
